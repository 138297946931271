import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col } from "react-bootstrap"
import ButtonLink from "../../components/buttonLink"
import roundlake from "../../images/topic-1/step-2/round-lake-map-default.jpg"
import roundlake2 from "../../images/topic-1/step-2/round-lake-map-sites.jpg"
import remarks1 from "../../images/topic-1/step-2/note-1.jpg"
import remarks2 from "../../images/topic-1/step-2/note-2.jpg"
import remarks3 from "../../images/topic-1/step-2/note-3.jpg"
import remarks4 from "../../images/topic-1/step-2/note-4.jpg"
import remarks5 from "../../images/topic-1/step-2/note-5.jpg"
import TitleSection from "../../components/titleSection"
import BottomNav from "../../components/bottomNav"
import MyBreadcrumb from "../../components/myBreadcrumb"
import parse from "html-react-parser"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const ExpGrowthStep2 = ({ location }) => {
  const { t } = useTranslation()

  const pageContent = {
    pageTitle: parse(t("Topic1_Step2_pageTitle")),
    breadcrumbs: {
      topicLink: parse(t("Topic1_Step2_breadcrumbs.topicLink")),
      topicTitle: parse(t("Topic1_Step2_breadcrumbs.topicTitle")),
      secondLink: "",
      secondTitle: "",
      currentTitle: parse(t("Topic1_Step2_breadcrumbs.currentTitle")),
    },
    title: parse(t("Topic1_Step2_title")),
    topText: parse(t("Topic1_Step2_topText")),
    bottomText: parse(t("Topic1_Step2_bottomText")),
    instructions: parse(t("Topic1_Step2_instructions")),
    tableTexts: [
      parse(t("Topic1_Step2_tableTexts[0]")),
      parse(t("Topic1_Step2_tableTexts[1]")),
      parse(t("Topic1_Step2_tableTexts[2]")),
      parse(t("Topic1_Step2_tableTexts[3]")),
      parse(t("Topic1_Step2_tableTexts[4]")),
      parse(t("Topic1_Step2_tableTexts[5]")),
    ],
    remarks: [
      parse(t("Topic1_Step2_remarks[0]")),
      parse(t("Topic1_Step2_remarks[1]")),
      parse(t("Topic1_Step2_remarks[2]")),
      parse(t("Topic1_Step2_remarks[3]")),
      parse(t("Topic1_Step2_remarks[4]")),
      parse(t("Topic1_Step2_remarks[5]")),
    ],
    yearNumbers: [
      [0, 0, 1, 0.33],
      [9, 8, 3, 6.7],
      [183, 126, 90, 133],
      ["3,380", "2,765", "1,869", "2,671"],
      ["68,274", "58,329", "34,524", "53,709"],
    ],
    images: [
      {
        src: roundlake,
        alt: parse(t("Topic1_Step2_images[0].alt")),
        caption: parse(t("Topic1_Step2_images[0].caption")),
      },
      {
        src: roundlake2,
        alt: parse(t("Topic1_Step2_images[1].alt")),
        caption: parse(t("Topic1_Step2_images[1].caption")),
      },
      {
        src: remarks1,
        alt: parse(t("Topic1_Step2_images[2].alt")),
        caption: parse(t("Topic1_Step2_images[2].caption")),
      },
      {
        src: remarks2,
        alt: parse(t("Topic1_Step2_images[3].alt")),
        caption: parse(t("Topic1_Step2_images[3].caption")),
      },
      {
        src: remarks3,
        alt: parse(t("Topic1_Step2_images[4].alt")),
        caption: parse(t("Topic1_Step2_images[4].caption")),
      },
      {
        src: remarks4,
        alt: parse(t("Topic1_Step2_images[5].alt")),
        caption: parse(t("Topic1_Step2_images[5].caption")),
      },
      {
        src: remarks5,
        alt: parse(t("Topic1_Step2_images[6].alt")),
        caption: parse(t("Topic1_Step2_images[6].caption")),
      },
    ],
    linkTexts: [
      parse(t("Topic1_Step2_linkTexts[0]")),
      parse(t("Topic1_Step2_linkTexts[1]")),
      parse(t("Topic1_Step2_linkTexts[2]")),
      parse(t("Topic1_Step2_linkTexts[3]")),
      parse(t("Topic1_Step2_linkTexts[4]")),
      "/exponentialgrowth/step1",
      "/exponentialgrowth/step3",
    ],
  }
  // =========================================================================================================

  const remarksImgArr = [
    pageContent.images[2].src,
    pageContent.images[3].src,
    pageContent.images[4].src,
    pageContent.images[5].src,
    pageContent.images[6].src,
  ]
  const [numClicks, setNumClicks] = useState(0)
  const [numClicksCal, setNumClicksCal] = useState(0)
  const [year, setYear] = useState(1)
  const [remarksImg, setRemarksImg] = useState(remarksImgArr[year - 1])
  // =========================================================================================================
  return (
    <Layout>
      <SEO title={pageContent.pageTitle} />
      <MyBreadcrumb
        topicLink={pageContent.breadcrumbs.topicLink}
        topicTitle={pageContent.breadcrumbs.topicTitle}
        secondLink={pageContent.breadcrumbs.secondLink}
        secondTitle={pageContent.breadcrumbs.secondTitle}
        currentTitle={pageContent.breadcrumbs.currentTitle}
      />
      <div id="top" className="homeContent">
        <TitleSection
          title={pageContent.title}
          subTitle={pageContent.topText}
          instructions={pageContent.instructions}
        />
        {/* TOP SECTION */}
        <div hidden={numClicks >= 2}>
          <Row>
            <Col sm="12">
              <p className="middleTitle">
                <span className="bold">
                  {pageContent.tableTexts[4]} {year}
                </span>
              </p>
            </Col>
            <br /> <br />
            <Col md="6" lg="8">
              <div class="imgContainer">
                <img
                  src={
                    numClicks == 1
                      ? pageContent.images[1].src
                      : pageContent.images[0].src
                  }
                  alt={
                    numClicks == 1
                      ? pageContent.images[1].alt
                      : pageContent.images[0].alt
                  }
                />
              </div>
            </Col>
            <Col md="6" lg="4">
              <h4 className="highlightColorOFF">{pageContent.tableTexts[0]}</h4>
              <table>
                <tr>
                  <th>{pageContent.tableTexts[1]}</th>
                  <th style={{ textAlign: "right" }}>
                    {pageContent.tableTexts[2]}
                  </th>
                </tr>
                <tr>
                  <td>1</td>
                  <td style={{ textAlign: "right" }}>
                    {numClicks >= 1 ? pageContent.yearNumbers[year - 1][0] : ""}
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td style={{ textAlign: "right" }}>
                    {numClicks >= 1 ? pageContent.yearNumbers[year - 1][1] : ""}
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td style={{ textAlign: "right" }}>
                    {numClicks >= 1 ? pageContent.yearNumbers[year - 1][2] : ""}
                  </td>
                </tr>
              </table>
              <ButtonLink
                btnText={pageContent.linkTexts[0]}
                className="btnNext"
                onClick={() => {
                  setNumClicks(numClicks + 1)
                }}
                disabled={numClicks >= 1}
              />
              <ButtonLink
                btnText={pageContent.linkTexts[1]}
                className="btnNext"
                onClick={() => {
                  setNumClicks(numClicks + 1)
                }}
                disabled={numClicks < 1}
              />
            </Col>
          </Row>
        </div>

        {/* BOTTOM SECTION */}
        <Row id="bottom" hidden={numClicks < 2}>
          <Col md="8">
            <h4 className="">{pageContent.remarks[0]}</h4>{" "}
            <img
              className="round"
              src={remarksImg}
              alt={pageContent.images[2].alt}
            />
            <p className="smText">{pageContent.images[year].caption}</p>
            <p>{pageContent.remarks[year]}</p>
          </Col>

          <Col sm="6" md="4">
            <h4 className="">{pageContent.tableTexts[3]}</h4>
            <table>
              <tr>
                <th>{pageContent.tableTexts[4]}</th>
                <th style={{ textAlign: "right" }}>
                  {pageContent.tableTexts[5]}
                </th>
              </tr>
              <tr>
                <td>1</td>
                <td style={{ textAlign: "right" }}>
                  {numClicksCal >= 1 ? pageContent.yearNumbers[0][3] : ""}
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td style={{ textAlign: "right" }}>
                  {numClicksCal >= 2 ? pageContent.yearNumbers[1][3] : ""}
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td style={{ textAlign: "right" }}>
                  {numClicksCal >= 3 ? pageContent.yearNumbers[2][3] : ""}
                </td>
              </tr>{" "}
              <tr>
                <td>4</td>
                <td style={{ textAlign: "right" }}>
                  {numClicksCal >= 4 ? pageContent.yearNumbers[3][3] : ""}
                </td>
              </tr>{" "}
              <tr>
                <td>5</td>
                <td style={{ textAlign: "right" }}>
                  {numClicksCal >= 5 ? pageContent.yearNumbers[4][3] : ""}
                </td>
              </tr>
            </table>
            <ButtonLink
              btnText={pageContent.linkTexts[2]}
              btnColor="#BB8329"
              className="btnNext"
              onClick={() => {
                setNumClicks(numClicks + 1)
                setNumClicksCal(numClicksCal + 1)
              }}
              disabled={numClicks >= 3}
            />
            <ButtonLink
              btnLink="#myHeader"
              btnText={pageContent.linkTexts[3]}
              btnColor="#4A331C"
              className="btnNext"
              onClick={() => {
                setYear(year + 1)
                setNumClicks(0)
                setRemarksImg(remarksImgArr[year])
              }}
              hidden={(numClicks < 3) | (year >= 5)}
            />
          </Col>
          <Col md="12"></Col>
        </Row>

        <Row hidden={(numClicks < 3) | (year < 5)}>
          <Col md="12">
            {" "}
            <p>{pageContent.bottomText}</p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm="12" md="12" className="alignRight">
            <BottomNav
              prevName={pageContent.linkTexts[4]}
              btnLinkPrev={pageContent.linkTexts[5]}
              btnLinkNext={pageContent.linkTexts[6]}
              nextName={parse(t("Next"))}
              disabled={(numClicks < 3) | (year < 5)}
            />
          </Col>
        </Row>
      </div>
      <br />
    </Layout>
  )
}

export default ExpGrowthStep2
