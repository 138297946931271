import React from "react"
import { Breadcrumb } from "react-bootstrap"
import { Link } from "gatsby"
import parse from "html-react-parser"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const MyBreadcrumb = ({
  topicLink,
  topicTitle,
  secondLink,
  secondTitle,
  currentTitle,
}) => {
  const { t } = useTranslation()

  // =========================================================================================================
  return (
    <Breadcrumb>
      <Breadcrumb.Item className="breadText bold">
        <Link to="/">{parse(t("Home"))}</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item className="breadText bold" hidden={topicTitle == ""}>
        {" "}
        <Link to={"/" + topicLink}>{topicTitle}</Link>
      </Breadcrumb.Item>
      {/* <Breadcrumb.Item hidden={secondTitle == ""}>
        <Link to={"/" + secondLink}>{secondTitle}</Link>
      </Breadcrumb.Item> */}
      <Breadcrumb.Item active className="breadTextNoBold">
        {currentTitle}
      </Breadcrumb.Item>
    </Breadcrumb>
  )
}

export default MyBreadcrumb
