import React from "react"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"

const ButtonLink = ({
  btnLink,
  btnText,
  btnColor,
  onClick,
  className,
  disabled,
  hidden,
  btnWidth,
}) => {
  return (
    <Link to={btnLink}>
      <Button
        onClick={onClick}
        variant="secondary"
        className={className}
        disabled={disabled}
        hidden={hidden}
        style={{ background: btnColor, border: btnColor, width: btnWidth }}
      >
        {/* <FontAwesomeIcon
          icon={btnIcon}
          color={btnIconColor}
          size="sm"
          transform="shrink-1"
          weight="normal"
        /> */}
        {btnText} &nbsp;
        {/* <FontAwesomeIcon
          icon={btnIcon}
          color={btnIconColor}
          size="sm"
          transform="shrink-1"
          weight="normal"
        /> */}
      </Button>
    </Link>
  )
}

export default ButtonLink
