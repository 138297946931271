import React from "react"
import { Row, Col } from "react-bootstrap"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const TitleSection = ({ title, subTitle, instructions }) => {
  return (
    <Row>
      <Col sm="12">
        {" "}
        <h3 className="blueTitle">{title}</h3>
        <p>{subTitle}</p>
      </Col>
      <Col sm="12">
        <p className="borderMe" style={{ width: "100%", padding: "20px" }}>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          &nbsp; {instructions}
        </p>
      </Col>
    </Row>
  )
}

export default TitleSection
